import styled, { css } from "styled-components";

export const Container = styled.div`
    ${props =>
        props.isModalOpen &&
        css`
            max-height: 100vh;
            overflow: hidden; ;
        `}
`;
