import styled, { css } from "styled-components";
import { layouts } from "../../../theme";

export const Container = styled.div`
    background-color: ${props => props.theme.white};
    color: ${props => props.theme.black};
    padding: 1rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    @media (max-width: ${layouts.tablet}) {
        padding: 0;
        justify-content: center;
    }
`;

export const Button = styled.button`
    border-radius: 25px;
    border: solid 3px ${props => props.theme.tertiary};
    color: ${props => props.theme.tertiary};
    width: fit-content;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: center;
    background-color: transparent;
    padding: 0 1.5rem;
    margin: 0.5rem;
    cursor: pointer;
    @media (max-width: ${layouts.tablet}) {
        font-size: 1rem;
    }
    @media (min-width: ${layouts.tablet}) {
        font-size: 1rem;
    }
    @media (min-width: ${layouts.desktop}) {
        font-size: 1.2rem;
    }

    @media (hover) {
        :hover {
            border-color: ${props => props.theme.primary};
            color: ${props => props.theme.primary};
        }
    }

    ${props =>
        props.active &&
        css`
            border-color: ${props => props.theme.primary};
            color: ${props => props.theme.primary};
        `}
`;
