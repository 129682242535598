import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { layouts } from "../../theme";

export const Container = styled.div`
  color: ${(props) => props.theme.black};
  @media (max-width: ${layouts.mobile}) {
    padding: 2rem;
  }
  @media (min-width: ${layouts.mobile}) {
    padding: 3.5rem;
  }
  @media (min-width: ${layouts.desktop}) {
    padding: 4rem;
  }
`;

export const Title = styled.h2`
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.87;
  letter-spacing: normal;
  margin: 0;
  margin-bottom: 2rem;
  @media (max-width: ${layouts.tablet}) {
    font-size: 2.25rem;
  }
  @media (min-width: ${layouts.tablet}) {
    font-size: 3rem;
  }
  @media (min-width: ${layouts.desktop}) {
    font-size: 3.75rem;
  }
`;

export const Subtitle = styled.h3`
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: normal;
  margin-bottom: 9rem;
  @media (max-width: ${layouts.tablet}) {
    font-size: 1.65rem;
    margin-bottom: 2rem;
  }
  @media (min-width: ${layouts.tablet}) {
    font-size: 2.25rem;
  }
  @media (min-width: ${layouts.desktop}) {
    font-size: 2.75rem;
  }
`;

export const LegalDesignThinkingPrinciplesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  @media (max-width: ${layouts.mobile}) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const Infographic = styled.img`
  max-height: 560px;
  width: 100%;
`;

export const PrinciplesContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const UnorderedList = styled.ul`
  list-style: none;
  padding-inline-start: 0px !important;
`;

export const ListItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
`;

export const ListIcon = styled(FontAwesomeIcon)`
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.93;
  letter-spacing: normal;
  text-align: center;
  color: ${(props) => props.theme.tertiary};
  margin-right: 1rem;
  @media (max-width: ${layouts.tablet}) {
    font-size: 2rem;
  }
  @media (min-width: ${layouts.tablet}) {
    font-size: 2.75rem;
    width: 6rem !important;
  }
  @media (min-width: ${layouts.desktop}) {
    font-size: 3.5rem;
  }
`;

export const ListDescription = styled.span`
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.32;
  letter-spacing: normal;
  @media (max-width: ${layouts.tablet}) {
    font-size: 1.5rem;
  }
  @media (min-width: ${layouts.tablet}) {
    font-size: 2rem;
  }
  @media (min-width: ${layouts.desktop}) {
    font-size: 2.375rem;
  }
`;

export const ImpactOnTheWorld = styled.div`
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.85;
  letter-spacing: normal;
  @media (max-width: ${layouts.tablet}) {
    font-size: 1rem;
  }
  @media (min-width: ${layouts.tablet}) {
    font-size: 1.5rem;
  }
  @media (min-width: ${layouts.desktop}) {
    font-size: 1.75rem;
  }
`;

export const ImagesContainer = styled.div`
  display: flex;
  grid-gap: 1rem;
  flex-wrap: wrap;
`;

export const ImpactLogo = styled.img`
  width: 5rem;
`;

export const SeparatorContainer = styled.div`
  margin-top: 9.5rem;
  margin-bottom: 9.5rem;
  @media (max-width: ${layouts.mobile}) {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
`;

export const Separator = styled.div`
  width: 100%;
  border-bottom: dashed 2px #26c6da;
`;

export const HelpContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 3rem;
  margin-bottom: 4rem;
  @media (max-width: ${layouts.mobile}) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const HelpContent = styled.div`
  font-weight: 300;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.32;
  letter-spacing: normal;
  @media (max-width: ${layouts.tablet}) {
    font-size: 1.5rem;
  }
  @media (min-width: ${layouts.tablet}) {
    font-size: 2rem;
  }
  @media (min-width: ${layouts.desktop}) {
    font-size: 2.375rem;
  }
`;

export const HelpSubtitle = styled.div`
  font-weight: normal;
  font-style: normal;
`;

export const HelpUl = styled.ul`
  list-style: "» ";
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 60px;
  letter-spacing: normal;
  padding-inline-start: 1.5rem;
  margin-bottom: 0;
  @media (max-width: ${layouts.tablet}) {
    font-size: 1.5rem;
  }
  @media (min-width: ${layouts.tablet}) {
    font-size: 2rem;
  }
  @media (min-width: ${layouts.desktop}) {
    font-size: 2.375rem;
  }
`;
