import React from "react";
import { FormattedMessage } from "react-intl";

import { Container, TalksButton, TalksLink } from "./styled";

const TalksSection = () => {
    return (
        <Container>
            <div>
                <FormattedMessage id="talks-section.title" />
            </div>
            <TalksLink href="https://www.calendly.com/designrights" target="_blank" rel="noreferrer">
                <TalksButton>
                    <FormattedMessage id="talks-section.button" />
                </TalksButton>
            </TalksLink>
        </Container>
    );
};

export default TalksSection;
