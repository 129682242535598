const it = {
    "blog-loading.title": "Caricamento ...",
    "blog-loading.subtitle": "Non siamo riusciti a trovare post del blog, riprova più tardi",
    "blog-loading.button": "Torna a Home",

    "blog-hero.title": "Blog",
    "blog-hero.subtitle": "Progetti presenti e futuri, eventi e notizie dal mondo legal design",

    "error-section.title": "Ti sei persə?",
    "error-section.subtitle": "Forse hai seguito un link rotto o  hai inserito una URL che non esiste in questo sito. ",
    "error-section.button": "Torna alla Home",

    "hero-section.title": "Legal design per {br} umanizzare il diritto",
    "hero-section.subtitle": "Semplificare, rendere accessibile, {br} generare consapevolezza",

    "awarness-section.title": "Maggiore fiducia in ambito legale grazie a contenuti chiari e trasparenti",
    "awarness-section.list.first": "facili da comprendere",
    "awarness-section.list.second": "inclusivi",
    "awarness-section.list.third": "ideati sui bisogni delle persone",

    "revolutionaries-section.title": "Il nostro impatto nel mondo del diritto",
    "revolutionaries-section.page1.first.subtitle": "Società leader nel settore delle telecomunicazioni",
    "revolutionaries-section.page1.first.description":
        "Sintesi delle norme d’uso per la telefonia mobile ricaricabile. Migliore chiarezza espositiva, grazie alla semplificazione del linguaggio, in modo che le persone siano in grado di prendere facilmente visione dell’intero documento e di comprenderne velocemente i contenuti.",
    "revolutionaries-section.page1.second.subtitle": "Società leader nel settore dell’energia",
    "revolutionaries-section.page1.second.description":
        "Sintesi contrattuale per il servizio fibra. Abbiamo migliorato velocità di lettura, la comprensione e la capacità di recall delle informazioni contenute nel documento in modo da consentire alle persone di prendere le decisioni in modo più consapevole.",
    "revolutionaries-section.page2.first.subtitle": "Società leader nel settore IT",
    "revolutionaries-section.page2.first.description":
        "Privacy policy del sito web. Riprogettazione del testo per rendere trasparente, semplice e chiaro l’utilizzo dei dati delle persone con attenzione al linguaggio plain. Redesign dell’architettura delle informazioni per rendere facilmente reperibili i contenuti più rilevanti.",
    "revolutionaries-section.page2.second.subtitle": "Società leader nel settore della cosmetica",
    "revolutionaries-section.page2.second.description":
        "Informativa per il trattamento dei dati personali e liberatoria per i diritti di immagine. Migliore chiarezza espositiva e velocità di lettura affinchè le persone esterne all’azienda possano prenderne velocemente visione e apporre la propria firma in modo rapido e consapevole.",
    "revolutionaries-section.page3.first.subtitle": "Società leader nel settore nella logistica",
    "revolutionaries-section.page3.first.description":
        "Formazione del team legale. Abbiamo trasmesso la metodologia che è alla base del processo di legal design thinking insieme ai valori che ne sono ispiratori e agli strumenti che consentono di metterla in pratica attraverso workshop di co-creazione.",
    "revolutionaries-section.page3.second.subtitle": "Società leader nel settore della cosmetica",
    "revolutionaries-section.page3.second.description": "Quale impatto vuoi generare tu?",
    "revolutionaries-section.page3.second.start-now": "Inizia adesso",

    "legal-design-thinking-section.title": "Legal Design Thinking",
    "legal-design-thinking-section.subtitle": "Un approccio nativamente multidisciplinare",
    "legal-design-thinking-section.list.first": "+ Trasparenza",
    "legal-design-thinking-section.list.second": "+ Fiducia nel brand",
    "legal-design-thinking-section.list.third": "+ Comprensione",
    "legal-design-thinking-section.list.fourth": "- Contenziosi",
    "legal-design-thinking-section.impact-on-the-world": "L'impatto sul mondo",
    "legal-design-thinking-section.how-we-can-help": "Come possiamo aiutarti?",
    "legal-design-thinking-section.how-we-can-help.consulting.title": "Consulenza",
    "legal-design-thinking-section.how-we-can-help.consulting.description":
        "Scrittura e riscrittura di contenuti giuridici",
    "legal-design-thinking-section.how-we-can-help.legal-design-workshop.title": "Formazione legal design",
    "legal-design-thinking-section.how-we-can-help.legal-design-workshop.description":
        "Percorsi di apprendimento per legali e personale aziendale",
    "legal-design-thinking-section.how-we-can-help.legal-tech-products.title": "Prodotti legal tech",
    "legal-design-thinking-section.how-we-can-help.legal-tech-products.description":
        "Ideazione e realizzazione di soluzioni innovative in ambito legal",
    "legal-design-thinking-section.how-we-can-help.tailor-made-services.title": "Progetti personalizzati",
    "legal-design-thinking-section.how-we-can-help.tailor-made-services.description": "Co-creazione di progetti custom",
    "legal-design-thinking-section.in-which-scope": "In quale ambito?",
    "legal-design-thinking-section.in-which-scope.contracts": "Contratti",
    "legal-design-thinking-section.in-which-scope.judicial-acts": "Atti giudiziari",
    "legal-design-thinking-section.in-which-scope.customer-service": "Customer service",
    "legal-design-thinking-section.in-which-scope.internal-policies": "Policy aziendali",
    "legal-design-thinking-section.in-which-scope.privacy": "Privacy",
    "legal-design-thinking-section.in-which-scope.and-more": "Altri che scopriremo insieme",

    "talks-section.title": "Disrupt the world with us!",
    "talks-section.button": "Parliamoci",

    "design-righters.bdm.name": "Barbara",
    "design-righters.bdm.surname": "de Muro",
    "design-righters.bdm.bio":
        "Follemente innamorata del diritto societario e del diritto in genere, da sempre amo pormi nell’intersezione tra diritto, creatività ed etica, la cui ricombinazione porta le architetture giuridiche a innovare se stesse, proiettandole nel futuro, verso inedite conquiste che fanno progredire la società. Insegno legal design all’Università Cattolica del Sacro Cuore di Milano.",
    "design-righters.cdr.name": "Cristina",
    "design-righters.cdr.surname": "Del Re",
    "design-righters.cdr.bio":
        "Aiuto le organizzazioni a trovare una visione creativa per innovare, comunicare e creare valore attraverso soluzioni concrete basate sulla comprensione della natura degli esseri umani. Viaggiatrice seriale e digital nomad, non mi sposto mai senza la mia reflex.",
    "design-righters.fm.name": "Francesco",
    "design-righters.fm.surname": "Mondora",
    "design-righters.fm.bio":
        "Sono un purposeful leader e - direbbero - imprenditore seriale. Il lavoro è una scusa per portare del bene collettivo e in ogni azione deve esserci la nostra impronta per evolvere, il nostro segno che sia rispettoso e inclusivo di e per tutti. È così che ho sempre costruito le mie organizzazioni portandole ad essere migliori per il mondo. Il resto su LinkedIn.",
    "design-righters.lz.name": "Lorenzo",
    "design-righters.lz.surname": "Zorzi",
    "design-righters.lz.bio":
        "Credo fortemente che la costanza dell’applicazione unita all’esercizio della visionarietà sia la ricetta per l’innovazione. Il risultato è, per intenderci, Glenn Gould. La lunga esperienza avuta sia nel campo della consulenza aziendale che della comunicazione digitale mi ha insegnato che si porta valore solo quando si lavora insieme ai propri clienti e mai quando si lavora al loro posto.",
    "design-righters.mi.name": "Marco",
    "design-righters.mi.surname": "Imperiale",
    "design-righters.mi.bio":
        "Pur avendo visto più di 1000 concerti, ho avuto il tempo di laurearmi in Italia (Bologna) e negli Stati Uniti (Harvard). Cammino ogni estate verso Santiago de Compostela, offro seminari di mindfulness e quando posso giro i laghi italiani con la mia motocicletta, di nome Blackie.",

    "together-rights-sections.title": "Insieme per i diritti",
    "together-rights-sections.lca.name": "LCA STUDIO LEGALE",
    "together-rights-sections.lca.description":
        "<b>LCA</b> è uno studio legale specializzato nell’assistenza legale e fiscale d’impresa, con sede a Milano, Genova, Treviso - anche all’interno dell’incubatore tecnologico H-Farm - e Dubai, dove opera in partnership internazionale con IAA law firm.",
    "together-rights-sections.mondora.name": "MONDORA SB SRL",
    "together-rights-sections.mondora.description":
        "<b>Mondora SB</b> srl è una società che offre servizi di consulenza IT, sviluppo software per applicazioni innovative, UX design e UX research, design thinking, tutoring e formazione in ambito IT.{br}{br}È una <b>Società Benefit ed è certificata B Corp</b> dal 2015 ed è parte del gruppo <b>TeamSystem.</b>",

    "book-section.title": "Legal Design",
    "book-section.subtitle": "Il libro",
    "book-section.created-by": "di Barbara de Muro e Marco Imperiale",
    "book-section.description":
        "Il primo libro di Legal Design che racchiude tutti gli aspetti fondamentali della disciplina: le ragioni del successo del legal design, il processo del legal design thinking, le tecniche di scrittura legale e del linguaggio visuale, le strategie di riscrittura di testi giuridici, le regole di organizzazione dei contenuti e di composizione della pagina.",
    "book-section.go-to-shop": "Vai allo shop",

    "footer.talk-with-us": "Parla con noi",
    "footer.copyright": "© 2021 mondora srl sb. All Rights Reserved. Viale Tunisia 4, 20124 Milano P.IVA 03680680968"
};

export default it;
