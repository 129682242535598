import styled from "styled-components";
import { Link } from "@reach/router";

import { layouts } from "../../theme";

const isActive =
    className =>
    ({ isCurrent }) => {
        return { className: `${className} ${isCurrent && "active"}` };
    };

const MagicLink = ({ className, children, ...props }) => (
    <Link getProps={isActive(className)} {...props}>
        {children}
    </Link>
);

export const Header = styled.header`
    height: 5.625rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    background-color: ${props => props.theme.black};
    color: ${props => props.theme.white};
    @media (max-width: ${layouts.mobile}) {
        padding: 2.75rem 2rem 0 2rem;
    }
    @media (min-width: ${layouts.mobile}) {
        padding: 2.75rem 3.5rem 0 3.5rem;
    }
    @media (min-width: ${layouts.desktop}) {
        padding: 2.75rem 7.5rem 0 7.5rem;
    }
`;

export const DesignRightsLogo = styled.img`
    @media (max-width: ${layouts.mobile}) {
        max-width: 200px;
    }
`;

export const Navigation = styled.nav`
    background-color: transparent;
    text-align: right;
`;

export const PageLink = styled(MagicLink)`
    background-color: transparent;
    color: ${props => props.theme.white};
    font-size: 1rem;
    margin: 0 8px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: right;
    text-decoration: none;
    cursor: pointer;

    &.active {
        color: ${props => props.theme.primary};
    }
`;

export const LangButton = styled.button`
    background-color: transparent;
    color: ${props => props.theme.white};
    font-size: 0.75rem;
    margin: 0 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: right;
    border: none;
    cursor: pointer;
`;
