import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { layouts } from "../../theme";

export const Container = styled.div`
    background-color: ${props => props.theme.black};
    color: ${props => props.theme.white};
    padding: 3.5rem;
    border-bottom: 2rem solid ${props => props.theme.secondary};
    @media (max-width: ${layouts.mobile}) {
        padding: 2rem;
    }
`;

export const Title = styled.h2`
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.07;
    letter-spacing: normal;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 5.5rem;
    max-width: 876px;
    @media (max-width: ${layouts.tablet}) {
        font-size: 2.25rem;
    }
    @media (min-width: ${layouts.tablet}) {
        font-size: 3rem;
    }
    @media (min-width: ${layouts.desktop}) {
        font-size: 3.75rem;
    }
`;

export const RevolutionistContainer = styled.div`
    display: grid;
    grid-template-columns: max-content max-content;
    grid-gap: 3rem;
    align-items: center;
    justify-content: center;
    padding-bottom: 7.5rem;

    @media (max-width: 1360px) {
        grid-template-columns: max-content 1fr;
    }

    @media (max-width: ${layouts.tablet}) {
        grid-template-columns: 1fr;
        margin-bottom: 2rem;
    }
`;

export const Revolutionist = styled.div`
    width: 190px;
    min-height: 128px;
    border: 2px solid ${props => props.theme.lightGrey};
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 0.875rem;

    @media (max-width: ${layouts.tablet}) {
        margin: 0 auto;
    }
`;

export const Button = styled.button`
    border-radius: 25px;
    border: solid 3px ${props => props.theme.white};
    font-size: 1.5rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: center;
    background-color: transparent;
    color: ${props => props.theme.white};
    padding: 0 3rem;
    cursor: pointer;
    @media (max-width: ${layouts.tablet}) {
        font-size: 1rem;
    }
    @media (min-width: ${layouts.tablet}) {
        font-size: 1.2rem;
    }
    @media (min-width: ${layouts.desktop}) {
        font-size: 1.5rem;
    }

    :hover {
        border-color: ${props => props.theme.primary};
        color: ${props => props.theme.primary};
    }
`;

export const RevolutionistDescription = styled.div`
    font-size: 1.5rem;
    max-width: 1000px;
    text-align: left;
`;

export const Icon = styled(FontAwesomeIcon)`
    font-size: 3.375rem;
    margin-bottom: 1rem;
`;

export const UnicornSection = styled.div`
    display: flex;
    justify-content: space-between;
    grid-gap: 1rem;

    @media (max-width: ${layouts.tablet}) {
        flex-direction: column;
    }
`;
