import React from "react";
import PropTypes from "prop-types";

import TopMenu from "../../components/top-menu";
import ErrorSection from "../../components/error-page/error-section";

import { Container } from "./styled";

const Error = ({ changeLang, locale }) => {
    return (
        <Container>
            <TopMenu changeLang={changeLang} locale={locale} />
            <ErrorSection />
        </Container>
    );
};

Error.propTypes = {
    changeLang: PropTypes.func,
    locale: PropTypes.string
};

export default Error;
