import styled from "styled-components";

export const Overlay = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgb(0, 0, 0, 0.5);
`;

export const PrivacyModalContainer = styled.div`
    width: 90%;
    overflow-y: scroll;
    background: ${props => props.theme.white};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 800px;
`;

export const Wrapper = styled.div`
    position: relative;
`;

export const Header = styled.div`
    position: sticky;
    top: 0;
    right: 0;
    width: 100%;
    padding: 3rem 3rem 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${props => props.theme.white};
    box-sizing: border-box;
`;

export const PrivacyTitle = styled.div`
    font-size: 3.75rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.87;
    letter-spacing: normal;
    color: ${props => props.theme.black};
`;

export const PrivacyContent = styled.div`
    font-size: 1.2rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.46;
    letter-spacing: normal;
    color: ${props => props.theme.black};
    margin: 2rem;
`;

export const PrivacySubtitle = styled.div`
    font-weight: bold;
    margin-top: 3rem;
`;

export const SecondSubtitle = styled.div`
    font-style: italic;
    text-decoration: underline;
    margin: 2rem 0 1rem;
`;

export const PrivacyUl = styled.ul`
    margin-bottom: 0;
`;

export const PrivacyParagraph = styled.p`
    margin-top: 0;
`;

export const PrivacyClose = styled.div`
    cursor: pointer;
    font-size: 2.75rem;

    :hover {
        color: ${props => props.theme.primary};
    }
`;
