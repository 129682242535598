import styled, { css } from "styled-components";
import { layouts } from "../../theme";

export const FeedContainer = styled.div`
    background-color: ${props => props.theme.white};
    margin: auto;
    padding: 2rem;
    max-width: 1200px;
    @media (max-width: ${layouts.mobile}) {
        margin: 0rem;
    }
`;

export const Container = styled.div`
    ${props =>
        props.isModalOpen &&
        css`
            max-height: 100vh;
            overflow: hidden; ;
        `}
`;
