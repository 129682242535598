import React from "react";

import { Parallax } from "react-scroll-parallax";

import { layouts } from "../../theme";
import { Container, DescriptionContainer, Icons, IconsContainer, ListItem, Title, UnorderedList } from "./styled";

import legaldesignLawDesignHumans from "../../image/legaldesign-law+design+humans.svg";
import { FormattedMessage } from "react-intl";

const AwarenessSection = () => {
    return (
        <Container>
            <IconsContainer>
                <Parallax
                    disabled={window.innerWidth < parseInt(layouts.mobile, 10)}
                    x={["-100px", "0px"]}
                    y={["0", "0"]}
                >
                    <Icons src={legaldesignLawDesignHumans} alt="legal design law design humans" />
                </Parallax>
            </IconsContainer>
            <DescriptionContainer>
                <Title>
                    <FormattedMessage id="awarness-section.title" />
                </Title>
                <UnorderedList>
                    <ListItem>
                        <FormattedMessage id="awarness-section.list.first" />
                    </ListItem>
                    <ListItem>
                        <FormattedMessage id="awarness-section.list.second" />
                    </ListItem>
                    <ListItem>
                        <FormattedMessage id="awarness-section.list.third" />
                    </ListItem>
                </UnorderedList>
            </DescriptionContainer>
        </Container>
    );
};

export default AwarenessSection;
