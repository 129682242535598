import styled from "styled-components";
import { layouts } from "../../theme";

export const Container = styled.div`
    padding: 8.75rem 5rem;
    background-color: ${props => props.theme.grey};
    @media (max-width: ${layouts.mobile}) {
        padding: 5rem 2rem;
    }
    @media (min-width: ${layouts.tablet}) {
        padding: 7.5rem 3.5rem;
    }
    @media (min-width: ${layouts.desktop}) {
        padding: 8.75rem 3.75rem;
    }
`;

export const Title = styled.h1`
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.87;
    letter-spacing: normal;
    text-align: center;
    margin-top: 0;
    @media (max-width: ${layouts.mobile}) {
        font-size: 2.25rem;
    }
    @media (min-width: ${layouts.tablet}) {
        font-size: 3rem;
    }
    @media (min-width: ${layouts.desktop}) {
        font-size: 3.75rem;
    }
`;

export const ContentWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 3rem;
    margin-bottom: 4rem;
    @media (max-width: ${layouts.tablet}) {
        grid-template-columns: repeat(1, 1fr);
    }
`;

export const Header = styled.a`
    display: flex;
    align-items: center;
    grid-gap: 1rem;
    font-size: 1.875rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.07;
    letter-spacing: normal;
    text-align: center;
    flex-wrap: wrap;
    text-decoration: none;
    color: ${props => props.theme.black};
    transition: transform 0.2s;

    :hover {
        transform: scale(1.05);
    }
`;

export const Description = styled.div`
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.32;
    letter-spacing: normal;
    margin-top: 4rem;
    @media (max-width: ${layouts.tablet}) {
        font-size: 1.5rem;
    }
    @media (min-width: ${layouts.tablet}) {
        font-size: 2rem;
    }
    @media (min-width: ${layouts.desktop}) {
        font-size: 2.375rem;
    }
`;
