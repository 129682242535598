import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";

import {
    PrivacyContent,
    PrivacyModalContainer,
    PrivacySubtitle,
    PrivacyTitle,
    SecondSubtitle,
    PrivacyUl,
    PrivacyParagraph,
    PrivacyClose,
    Header,
    Wrapper,
    Overlay
} from "./styled";

const PrivacyModal = ({ setIsPrivacyModalOpen }) => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    }, []);

    return (
        <Overlay>
            <PrivacyModalContainer>
                <Wrapper>
                    <Header>
                        <PrivacyTitle>Privacy</PrivacyTitle>
                        <PrivacyClose onClick={() => setIsPrivacyModalOpen(false)}>
                            <FontAwesomeIcon icon={faTimes} />
                        </PrivacyClose>
                    </Header>
                    <PrivacyContent>
                        <div>
                            <PrivacySubtitle>Perché questa informativa?</PrivacySubtitle>
                            Con il presente documento, vengono fornite le informazioni relative al trattamento dei dati
                            personali degli utenti (gli “<b>Utenti</b>”) del sito web www.designrights.com, così come
                            previsto dalla normativa vigente (il Regolamento UE 2016/679 - “GDPR”, il D.Lgs. 196/2003 e
                            ss.mm.ii. - “Codice Privacy” e i provvedimenti adottati dal Garante per la Protezione dei
                            Dati Personali).
                        </div>
                        <div>
                            <PrivacySubtitle>Chi tratta i dati personali?</PrivacySubtitle>
                            <PrivacyParagraph>
                                I dati personali saranno trattati da:
                                <PrivacyUl>
                                    <li>
                                        <b>LCA Studio Legale</b> con sede in Milano, 20121, Via della Moscova 18, P.IVA
                                        04385250966 (e che d’ora in poi chiameremo “<b>LCA</b>”). LCA è uno studio
                                        specializzato nell’assistenza legale e fiscale d’impresa con sede a Milano,
                                        Genova e Treviso – anche all’interno dell’incubatore tecnologico H-Farm – e a
                                        Dubai in partnership con IAA law firm;
                                    </li>
                                    <li>
                                        <b>Mondora SB S.r.l.</b> (Società benefit) con sede legale in Milano, 20124,
                                        Viale Tunisia 4, P.IVA 03680680968 (e che d’ora in poi chiameremo “
                                        <b>Mondora</b>”). Mondora è una società che offre servizi di consulenza IT,
                                        sviluppo software per applicazioni innovative, UX design e UX research, design
                                        thinking, tutoring e formazione in ambito IT. Mondora è una società benefit ed è
                                        certificata B-Corp.
                                    </li>
                                </PrivacyUl>
                                (d’ora in poi, quando ci riferiremo a LCA e Mondora insieme, le chiameremo “
                                <b>Società</b>”).
                            </PrivacyParagraph>
                            <PrivacyParagraph>
                                Le Società utilizzano e scelgono insieme come e per cosa utilizzare i dati personali;
                                per poterlo fare hanno sottoscritto un apposito contratto. In “legalese” si dice che
                                trattano i dati in qualità di <b>contitolari del trattamento</b>.
                            </PrivacyParagraph>
                            In caso di dubbi su come le Società trattano i dati, è sempre possibile contattarle:
                            <ul>
                                <li>all’indirizzo e-mail privacy@mondora.com e newsletter@lcalex.it;</li>
                                <li>all’indirizzo LCA Studio Legale - Via della Moscova 18, 20121, Milano.</li>
                            </ul>
                            <PrivacyParagraph>
                                Le informazioni che trovi in questa pagina non si applicano a siti, <i>app</i> e
                                contenuti di terzi di qualsiasi genere, neppure se accessibili da o tramite la
                                Piattaforma cliccando su <i>link</i> in esso presenti. In questi casi potranno essere
                                applicabili le disposizioni sulla protezione dei dati di tali terzi, che possono essere
                                diverse da queste e che ti consigliamo di consultare prima della comunicazione di
                                qualsiasi dato.
                            </PrivacyParagraph>
                        </div>
                        <div>
                            <PrivacySubtitle>Quali dati personali sono trattati?</PrivacySubtitle>
                            <PrivacyParagraph>
                                <SecondSubtitle>Dati di navigazione raccolti automaticamente dal Sito</SecondSubtitle>
                                Ogni qualvolta gli Utenti accedono al Sito, i sistemi informatici e le procedure{" "}
                                <i>software</i> preposte al funzionamento del Sito acquisiscono, nel corso del loro
                                normale esercizio, i dati di accesso e navigazione (ad esempio: indirizzi IP o i nomi a
                                dominio dei <i>computer</i> utilizzati dagli Utenti, gli indirizzi in notazione URI –{" "}
                                <i>Uniform Resource Identifier</i> – delle risorse richieste, l’orario della richiesta,
                                il metodo utilizzato nel sottoporre la richiesta al <i>server</i>, la dimensione del
                                file ottenuto in risposta, il codice numerico indicante lo stato della risposta data dal
                                server ed altri parametri relativi al sistema operativo e all’ambiente informatico
                                dell’Utente – i “<b>Dati di Navigazione</b>”).
                            </PrivacyParagraph>
                            <PrivacyParagraph>
                                <SecondSubtitle>
                                    Dati comunicati dagli Utenti per entrare in contatto con le Società
                                </SecondSubtitle>
                                Nel footer del sito e tramite gli appositi bottoni “Parliamoci” è possibile entrare in
                                contatto con le Società, scrivendo all’indirizzo e-mail indicato o compilando l’apposito
                                form e comunicando alcuni dati personali (nome, cognome, indirizzo e-mail, eventuali
                                dati personali contenuti nel messaggio di accompagnamento – i “<b>Dati di Contatto</b>
                                ”).
                            </PrivacyParagraph>
                            <PrivacyParagraph>
                                In questo modo, l’Utente potrà contattare le Società e ottenere informazioni sui servizi
                                offerti e sull’attività che svolgono le Società.
                            </PrivacyParagraph>
                            <PrivacyParagraph>
                                Il conferimento dei Dati di Contatto avviene su base volontaria: se gli Utenti non li
                                forniscono possono ancora navigare sul Sito senza poter però interagire con e chiedere
                                informazioni alle Società.
                            </PrivacyParagraph>
                            Gli Utenti sono invitati a non comunicare dati personali di terzi, a meno che non sia: in
                            questo caso, ricordiamo che è compito dell’Utente assolvere gli adempimenti di legge in
                            materia di tutela dei dati personali e, in particolare, informare i terzi della
                            comunicazione e raccoglierne, se del caso, il consenso.
                            <SecondSubtitle>Cookies</SecondSubtitle>
                            Sul Sito sono utilizzate delle tecniche informatiche per l’acquisizione diretta di dati
                            personali identificativi dell’utente consistenti in “stringhe di codice”: i “cookies”. Per
                            tutte le informazioni sui cookies attivi sul Sito e sui correlati trattamenti di dati
                            personali <b>clicca qui</b>.
                        </div>
                        <div>
                            <PrivacySubtitle>
                                Quali sono le finalità e le basi giuridiche del trattamento?
                            </PrivacySubtitle>
                            <PrivacyParagraph>
                                I <b>Dati di Navigazione</b> saranno utilizzati: <b>(i)</b> al fine di monitorare il
                                corretto funzionamento del Sito, <b>(ii)</b> in forma anonima e aggregata, per finalità
                                statistiche legate alla comprensione di come il Sito viene utilizzato dagli Utenti, per
                                migliorare la facilità di accesso e aumentarne l’attrattività, nonché <b>(iii)</b> per
                                rilevare eventuali problemi tecnici il prima possibile.
                                <br />
                                Detti trattamenti trovano la propria <u>base giuridica</u> nel{" "}
                                <u>legittimo interesse</u> delle Società al miglioramento dei propri servizi digitali, e
                                risulta compatibile con la posizione degli interessati in quanto: <i>(a)</i> il
                                monitoraggio del funzionamento del Sito e i dati statistici correlati al suo utilizzo
                                non comporta identificazione diretta; e <i>(b)</i> l’interesse della Società appare
                                ragionevolmente essere proprio anche degli Utenti, i quali potranno così fruire di un
                                Sito sempre più performante e ottimizzato.
                            </PrivacyParagraph>
                            <PrivacyParagraph>
                                I <b>Dati di Contatto</b> saranno utilizzati per rispondere alle richieste degli Utenti,
                                fornire chiarimenti e informazioni sui servizi offerti e sull’attività svolta dalle
                                Società.
                                <br />
                                Tale trattamento trova la propria <u>base giuridica</u> nella necessità di{" "}
                                <u>dare esecuzione a misure precontrattuali</u> (ad es., rispondere a specifici
                                chiarimenti e richieste di informazioni) <u>adottate su specifica richiesta</u>{" "}
                                dell’Utente.
                            </PrivacyParagraph>
                            Nel caso si rendesse necessario, i Dati potranno anche essere utilizzati, a fronte
                            dell'interesse legittimo delle Società, al fine di svolgere attività difensive o fare valere
                            o difendere un diritto delle Società in sede giudiziaria.
                        </div>
                        <div>
                            <PrivacySubtitle>Chi può conoscere i Dati? a chi sono comunicati i Dati?</PrivacySubtitle>
                            <PrivacyParagraph>
                                I Dati di Navigazione e i Dati di Contatto (che d’ora in poi chiameremo “<b>Dati</b>”)
                                saranno trattati e potranno essere conosciuti da dipendenti e collaboratori delle
                                Società, appositamente designati al trattamento.
                            </PrivacyParagraph>
                            <PrivacyParagraph>
                                I Dati potranno essere comunicati a soggetti terzi laddove necessario per lo svolgimento
                                di operazioni connesse alla gestione del Sito. In tal caso, i terzi destinatari dei Dati
                                – qualificati, a seconda delle circostanze, quali titolari autonomi del trattamento o
                                debitamente designati quali responsabili del trattamento – appartengono alle seguenti
                                categorie:
                                <ul>
                                    <li>
                                        società che prestano servizi accessori o strumentali alle attività di consulenza
                                        svolte dalle Società, in relazione allo sviluppo e alla gestione del Sito;
                                    </li>
                                </ul>
                                I Dati potranno inoltre essere conosciuti dalle Autorità competenti in caso di
                                specifiche richieste cui il Titolare è tenuto, per legge, a dare seguito, nonché dai
                                consulenti o dalle società che prestano servizi di natura tecnica ed organizzativa o di
                                assistenza professionale/consulenza per la gestione del contenzioso e per l'assistenza
                                legale nel caso di eventuali controversie per cui si rendesse necessario il loro
                                coinvolgimento.
                            </PrivacyParagraph>
                            L'Utente potrà richiedere alle Società la lista dei soggetti esterni che svolgono la loro
                            attività quali responsabili del trattamento utilizzando i recapiti indicati in questa
                            informativa.
                        </div>
                        <div>
                            <PrivacySubtitle>
                                Come sono trattati, protetti e conservati i Dati? Dove i Dati sono conservati?
                            </PrivacySubtitle>
                            <PrivacyParagraph>
                                I Dati saranno trattati sia in forma cartacea sia con strumenti elettronici, informatici
                                o automatizzati, tramite sistemi che ne assicurano la protezione, la sicurezza e la
                                riservatezza.
                            </PrivacyParagraph>
                            <PrivacyParagraph>
                                Le Società, inoltre, hanno adottato specifiche e adeguate misure di sicurezza logiche,
                                giuridiche organizzative e tecniche per prevenire la perdita dei Dati, usi illeciti o
                                non consentiti degli stessi e accessi non autorizzati.
                            </PrivacyParagraph>
                            <PrivacyParagraph>
                                I trattamenti avvengono presso server ubicati all'interno del territorio europeo e
                                sistemi informatici collocati presso i locali e le sedi delle Società.
                            </PrivacyParagraph>
                            Eventuali ulteriori trattamenti che comportino il trasferimento dei Dati all’estero avverrà,
                            se verso Paesi che non beneficiano di una decisione di adeguatezza della Commissione Europea
                            e che, quindi, non offrono un livello di protezione adeguato ai dati personali, soltanto{" "}
                            <b>(i)</b> previa adozione di opportune garanzie quali, a titolo esemplificativo, la
                            sottoscrizione delle clausole tipo approvate dalla Commissione Europea, o <b>(ii)</b> al
                            ricorrere di una deroga al divieto di trasferimenti al di fuori dell’Unione Europea e,
                            quindi, sempre a titolo esemplificativo, previa raccolta del consenso esplicito ed informato
                            dell’Utente, o ancora soltanto nella misura in cui sia necessario all’esecuzione di un
                            contratto concluso tra l’Utente e le Società, o tra le Società e un terzo a beneficio
                            dell’Utente, ovvero all'esecuzione di misure precontrattuali adottate su istanza
                            dell’Utente.
                        </div>
                        <div>
                            <PrivacySubtitle>Per quanto tempo sono conservati i Dati?</PrivacySubtitle>I Dati sono
                            conservati fino a 24 (ventiquattro) mesi dopo la ricezione della richiesta da parte
                            dell’Utente. I Dati saranno conservati per periodi di tempo maggiori laddove necessario a
                            adempiere ad obblighi regolamentari e/o di legge, nonché ad assicurare la tutela giudiziaria
                            dei diritti delle Società, nel rispetto dei termini di prescrizione ordinari.
                        </div>
                        <div>
                            <PrivacySubtitle>Quali sono i tuoi diritti?</PrivacySubtitle>
                            Gli Utenti, quali interessati (ossia, soggetti a cui si riferiscono i Dati), sono titolari
                            di diritti conferiti dal GDPR. In particolare, ai sensi degli artt. 15-23 del GDPR, gli
                            interessati hanno il diritto di:
                            <PrivacyUl>
                                <li>
                                    chiedere e ottenere, in qualunque momento, l’accesso ai propri dati personali,
                                    informazioni sul trattamento effettuato;
                                </li>
                                <li>
                                    chiedere e ottenere, in qualunque momento, la rettifica e/o l’aggiornamento dei dati
                                    personali, la cancellazione e la limitazione del trattamento;
                                </li>
                                <li>
                                    opporsi al trattamento e di chiedere la portabilità dei dati (cioè ricevere i dati
                                    personali in un formato strutturato di uso comune, leggibile da dispositivi
                                    automatici);
                                </li>
                                <li>
                                    revocare il proprio consenso in qualsiasi momento (ciò, in ogni caso, non
                                    pregiudicherà la liceità dei trattamenti effettuati sulla base del consenso reso
                                    prima della revoca);
                                </li>
                                <li>
                                    proporre reclamo a un’autorità di controllo (in Italia: il Garante per la Protezione
                                    dei Dati Personali).
                                </li>
                            </PrivacyUl>
                            I diritti di cui sopra potranno essere esercitati in ogni momento, mediante semplice
                            richiesta al Titolare, da trasmettere:
                            <PrivacyUl>
                                <li>
                                    all’indirizzo e-mail <a href="mailto:privacy@mondora.com">privacy@mondora.com</a> e{" "}
                                    <a href="mailto:newsletter@lcalex.it">newsletter@lcalex.it</a>;
                                </li>
                                <li>all’indirizzo LCA Studio Legale - Via della Moscova 18, 20121, Milano.</li>
                            </PrivacyUl>
                        </div>
                    </PrivacyContent>
                </Wrapper>
            </PrivacyModalContainer>
        </Overlay>
    );
};

export default PrivacyModal;
