import styled, { css } from "styled-components";
import { layouts } from "../../theme";

export const Container = styled.div`
  @media (max-width: ${layouts.mobile}) {
    padding: 2rem;
  }
  @media (min-width: ${layouts.mobile}) {
    padding: 3.5rem;
  }
  @media (min-width: ${layouts.desktop}) {
    padding: 5rem;
  }
`;

export const Title = styled.h1`
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.87;
  letter-spacing: normal;
  text-align: center;
  @media (max-width: ${layouts.tablet}) {
    font-size: 2.25rem;
  }
  @media (min-width: ${layouts.tablet}) {
    font-size: 3rem;
  }
  @media (min-width: ${layouts.desktop}) {
    font-size: 3.75rem;
  }
`;

export const RightersContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 2rem;
  overflow: auto;
  white-space: nowrap;
  padding: 1rem;
`;

export const RighterImage = styled.img`
  max-width: 280px;
  @media (max-width: ${layouts.mobile}) {
    max-width: 200px;
  }

  transition: transform 0.3s ease;
`;

export const Righter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  ${(props) =>
    props.selected &&
    css`
      color: ${(props) => props.theme.tertiary};
    `}

  :hover,
  :focus {
    color: ${(props) => props.theme.primary};
    outline: none;
    & ${RighterImage} {
      transform: scale(1.1);
    }
  }
`;

export const Name = styled.div`
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.07;
  letter-spacing: normal;
  text-align: center;
  margin-top: 2rem;
  @media (max-width: ${layouts.tablet}) {
    font-size: 1.2rem;
  }
  @media (min-width: ${layouts.tablet}) {
    font-size: 1.5rem;
  }
  @media (min-width: ${layouts.desktop}) {
    font-size: 2rem;
  }
`;

export const BioContainer = styled.div`
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.32;
  letter-spacing: normal;
  border-left: solid 3px ${(props) => props.theme.tertiary};
  padding-left: 2rem;
  margin-top: 3rem;
  max-width: 1342px;
  @media (max-width: ${layouts.tablet}) {
    font-size: 1.5rem;
  }
  @media (min-width: ${layouts.tablet}) {
    font-size: 2rem;
  }
  @media (min-width: ${layouts.desktop}) {
    font-size: 2.375rem;
  }
`;
