import React from "react";

import {
    ContactContainer,
    Container,
    Email,
    TalkWithUs,
    DesignRightsLogo,
    SocialIconsContainer,
    IconContainer,
    IconLink,
    PrivacyContainer,
    CompanyLogo,
    Copyright
} from "./styled";
import logoDesignRights from "../../image/logo-design-rights-footer.svg";
import logoLca from "../../image/lca-footer.svg";
import logoMondora from "../../image/mondora-footer.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram, faLinkedin, faTelegram, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { FormattedMessage } from "react-intl";
const Footer = ({ setIsPrivacyModalOpen }) => {
    return (
        <Container>
            <DesignRightsLogo
                src={logoDesignRights}
                alt="logo design rights footer"
                onClick={() => window.scrollTo(0, 0)}
            />
            <ContactContainer>
                <div>
                    <TalkWithUs>
                        <FormattedMessage id="footer.talk-with-us" />
                    </TalkWithUs>
                    <Email href="mailto:talk@designrights.com">talk@designrights.com</Email>
                    <CompanyLogo href="https://www.lcalex.it/" target="_blank" rel="noreferrer">
                        <img src={logoLca} alt="logo lca footer" />
                    </CompanyLogo>
                    <CompanyLogo href="https://www.mondora.com/" target="_blank" rel="noreferrer">
                        <img src={logoMondora} alt="logo mondora footer" />
                    </CompanyLogo>
                </div>
                <SocialIconsContainer>
                    <IconContainer>
                        <IconLink href="https://www.linkedin.com/company/designrights" target="_blank" rel="noreferrer">
                            <FontAwesomeIcon icon={faLinkedin} />
                        </IconLink>
                    </IconContainer>
                    <IconContainer>
                        <IconLink href="https://www.instagram.com/design.rights/" target="_blank" rel="noreferrer">
                            <FontAwesomeIcon icon={faInstagram} />
                        </IconLink>
                    </IconContainer>
                    <IconContainer>
                        <IconLink href="https://twitter.com/DesignRights" target="_blank" rel="noreferrer">
                            <FontAwesomeIcon icon={faTwitter} />
                        </IconLink>
                    </IconContainer>
                    <IconContainer>
                        <IconLink href="https://t.me/designrights" target="_blank" rel="noreferrer">
                            <FontAwesomeIcon icon={faTelegram} />
                        </IconLink>
                    </IconContainer>
                </SocialIconsContainer>
            </ContactContainer>
            <PrivacyContainer onClick={() => setIsPrivacyModalOpen(true)}>{"Privacy & Cookies"}</PrivacyContainer>
            <Copyright>
                <FormattedMessage id="footer.copyright" />
            </Copyright>
        </Container>
    );
};

export default Footer;
