import styled from "styled-components";
import { layouts } from "../../theme";

export const Container = styled.footer`
    background-color: ${props => props.theme.black};
    color: ${props => props.theme.white};
    padding: 3.75rem 4rem;
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-gap: 4.25rem;
    @media (max-width: ${layouts.mobile}) {
        padding: 3.75rem 2rem;
        grid-template-columns: 1fr;
    }
    @media (min-width: ${layouts.mobile}) {
        padding: 3.75rem 3.5rem;
    }
    @media (min-width: ${layouts.desktop}) {
        padding: 3.75rem 5rem;
    }
`;

export const TalkWithUs = styled.div`
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    @media (max-width: ${layouts.mobile}) {
        font-size: 1.125rem;
    }
    @media (min-width: ${layouts.tablet}) {
        font-size: 1.5rem;
    }
    @media (min-width: ${layouts.desktop}) {
        font-size: 1.875rem;
    }
`;

export const Email = styled.a`
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    font-weight: normal;
    margin-top: 0.5rem;
    margin-bottom: 2rem;
    color: ${props => props.theme.white};
    display: block;
    text-decoration: none;
    transition: transform 0.2s;
    :hover {
        color: ${props => props.theme.primary};
        transform: scale(1.05);
    }
    @media (max-width: ${layouts.mobile}) {
        font-size: 1.125rem;
    }
    @media (min-width: ${layouts.tablet}) {
        font-size: 1.5rem;
    }
    @media (min-width: ${layouts.desktop}) {
        font-size: 1.875rem;
    }
`;

export const ContactContainer = styled.div`
    align-self: end;
    display: flex;
    justify-content: space-between;
    @media (max-width: ${layouts.mobile}) {
        flex-wrap: wrap;
    }
`;

export const DesignRightsLogo = styled.img`
    cursor: pointer;
    @media (max-width: ${layouts.mobile}) {
        max-width: 200px;
    }
`;

export const SocialIconsContainer = styled.div`
    display: flex;
    grid-gap: 1rem;
`;

export const IconLink = styled.a`
    color: inherit;
`;

export const IconContainer = styled.div`
    transition: transform 0.2s;
    font-size: 3rem;
    cursor: pointer;

    :hover {
        color: ${props => props.theme.primary};
        transform: scale(1.05);
    }
`;

export const PrivacyContainer = styled.div`
    margin-top: 4rem;
    text-align: center;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    cursor: pointer;
    color: ${props => props.theme.white};

    @media (max-width: ${layouts.mobile}) {
        font-size: 1rem;
        margin-top: 0;
    }
    @media (min-width: ${layouts.tablet}) {
        font-size: 1.5rem;
    }
    @media (min-width: ${layouts.desktop}) {
        font-size: 1.875rem;
    }
`;

export const Copyright = styled.div`
    font-size: 1.25rem;
    margin-top: 4rem;
    font-weight: normal;
    letter-spacing: normal;
    @media (max-width: ${layouts.mobile}) {
        font-size: 1rem;
        margin-top: 0;
    }
    @media (min-width: ${layouts.tablet}) {
        font-size: 1.5rem;
    }
    @media (min-width: ${layouts.desktop}) {
        font-size: 1.875rem;
    }
`;

export const CompanyLogo = styled.a`
    transition: transform 0.2s;
    cursor: pointer;
    display: inline-flex;
    margin-right: 1rem;

    :hover {
        transform: scale(1.05);
    }

    @media (max-width: ${layouts.mobile}) {
        margin-bottom: 2rem;
    }
`;
