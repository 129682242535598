import styled from "styled-components";
import { layouts } from "../../../theme";

export const Container = styled.div`
    background-color: ${props => props.theme.white};
    margin: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    @media (max-width: ${layouts.mobile}) {
        margin: 0rem;
    }
`;
export const PostContainer = styled.div`
    @media (max-width: ${layouts.tablet}) {
        width: 100%;
    }
    @media (min-width: ${layouts.tablet}) {
        width: 50%;
    }
    @media (min-width: ${layouts.desktop}) {
        width: 50%;
    }
`;

export const PostWrapper = styled.div`
    border: 1px solid ${props => props.theme.primary};
    margin: 2rem;
    @media (max-width: ${layouts.mobile}) {
        margin: 2rem 0;
    }
`;
export const SuperLink = styled.a`
    color: ${props => props.theme.black};
    text-decoration: none;
`;

export const Thumbnail = styled.img`
    width: 100%;
    max-height: 400px;
    object-fit: cover;
`;
export const PostDescription = styled.div`
    margin: 2rem 1rem;
`;

export const PostAuthor = styled.div`
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.07;
    letter-spacing: normal;
    margin: 1rem;
    font-size: 1.2rem;
`;

export const PostTitle = styled.h2`
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.07;
    letter-spacing: normal;
    margin: 1rem;
    font-size: 2rem;
`;

export const PostSubtitle = styled.div`
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.07;
    letter-spacing: normal;
    margin: 1rem;
    font-size: 1rem;
`;
