import React, { useState } from "react";
import { BioContainer, Container, Name, Righter, RightersContainer, Title, RighterImage } from "./styled";

import francescoMondora from "../../image/francesco-mondora.svg";
import cristinaDelRe from "../../image/cristina-del-re.svg";
import barbaraDeMuro from "../../image/barbara-de-muro.svg";
import lorenzoZorzi from "../../image/lorenzo-zorzi.svg";
import { useIntl } from "react-intl";

const DesignRighters = () => {
    const intl = useIntl();

    const righters = [
        {
            id: "bdm",
            name: intl.formatMessage({ id: "design-righters.bdm.name" }),
            surname: intl.formatMessage({ id: "design-righters.bdm.surname" }),
            img: barbaraDeMuro,
            altImg: "barbara de muro",
            bio: intl.formatMessage({ id: "design-righters.bdm.bio" })
        },
        {
            id: "cdr",
            name: intl.formatMessage({ id: "design-righters.cdr.name" }),
            surname: intl.formatMessage({ id: "design-righters.cdr.surname" }),
            img: cristinaDelRe,
            altImg: "cristina del re",
            bio: intl.formatMessage({ id: "design-righters.cdr.bio" })
        },
        {
            id: "fm",
            name: intl.formatMessage({ id: "design-righters.fm.name" }),
            surname: intl.formatMessage({ id: "design-righters.fm.surname" }),
            img: francescoMondora,
            altImg: "francesco mondora",
            bio: intl.formatMessage({ id: "design-righters.fm.bio" })
        },
        {
            id: "lz",
            name: intl.formatMessage({ id: "design-righters.lz.name" }),
            surname: intl.formatMessage({ id: "design-righters.lz.surname" }),
            img: lorenzoZorzi,
            altImg: "lorenzo zorzi",
            bio: intl.formatMessage({ id: "design-righters.lz.bio" })
        }
    ];

    const [selectedRighter, setSelectedRighter] = useState(righters[0].id);

    return (
        <Container>
            <Title>DesignRighters</Title>
            <RightersContainer>
                {righters.map(righter => (
                    <Righter
                        selected={righter.id === selectedRighter}
                        key={righter.id}
                        onClick={() => setSelectedRighter(righter.id)}
                        tabIndex="0"
                    >
                        <RighterImage src={righter.img} alt={righter.altImg} />
                        <Name>
                            <div>{righter.name}</div>
                            <div>{righter.surname}</div>
                        </Name>
                    </Righter>
                ))}
            </RightersContainer>
            <BioContainer>{righters.find(righter => righter.id === selectedRighter).bio}</BioContainer>
        </Container>
    );
};

export default DesignRighters;
