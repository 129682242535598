import React from "react";
import PropTypes from "prop-types";

import { Link } from "@reach/router";

import { Header, DesignRightsLogo, Navigation, PageLink, LangButton } from "./styled";
import logoDesignRights from "../../image/logo-design_rights.svg";

const pages = [
    { title: "HOME", link: "/" },
    { title: "BLOG", link: "/blog" }
];

const TopMenu = ({ changeLang, locale }) => {
    // const isActive =
    //     className =>
    //     ({ isCurrent }) => {
    //         return isCurrent ? { className: `${className} bob` } : {};
    //     };

    return (
        <Header>
            <Link to={"/"}>
                <DesignRightsLogo src={logoDesignRights} alt="logo design rights" />
            </Link>
            <Navigation>
                {pages.map(page => (
                    <PageLink to={page.link} key={page.link}>
                        {page.title}
                    </PageLink>
                ))}
                <LangButton onClick={() => changeLang(locale === "it" ? "en" : "it")}>
                    {locale === "it" ? "EN" : "IT"}
                </LangButton>
            </Navigation>
        </Header>
    );
};

TopMenu.propTypes = {
    changeLang: PropTypes.func,
    locale: PropTypes.string
};

export default TopMenu;
