import styled from "styled-components";
import { layouts } from "../../theme";

export const Container = styled.div`
  background-color: ${(props) => props.theme.primary};
  color: ${(props) => props.theme.black};
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: auto;
  grid-template-areas: ". icon icon icon . content content content content content content .";
  align-items: center;
  @media (max-width: ${layouts.mobile}) {
    grid-template-columns: repeat(6, 1fr);
    grid-template-areas:
    ". icon icon icon icon . "
    " . content content content content .";
  }
`;

export const Title = styled.h3`
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.32;
  letter-spacing: normal;
  @media (max-width: ${layouts.tablet}) {
    font-size: 1.5rem;
  }
   @media (min-width: ${layouts.tablet}) {
    font-size: 2rem;
  }
  @media (min-width: ${layouts.desktop}) {
    font-size: 2.375rem;
  }
`;

export const IconsContainer = styled.div`
  grid-area: icon;
`;

export const Icons = styled.img`
  width: 100%
`;

export const DescriptionContainer = styled.div`
  grid-area: content;
`;

export const UnorderedList = styled.ul`
  list-style: "» ";
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.32;
  letter-spacing: normal;
  padding-inline-start: 1.5rem;
  letter-spacing: normal;
  @media (max-width: ${layouts.tablet}) {
    font-size: 1.5rem;
  }
   @media (min-width: ${layouts.tablet}) {
    font-size: 2rem;
  }
  @media (min-width: ${layouts.desktop}) {
    font-size: 2.375rem;
  }
`;

export const ListItem = styled.li`
  ::marker {
    font-weight: bold;
  }
`
