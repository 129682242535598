import styled from "styled-components";
import { layouts } from "../../theme";

export const Container = styled.div`
    @media (max-width: ${layouts.mobile}) {
        padding: 2.75rem 2rem;
    }
    @media (min-width: ${layouts.mobile}) {
        padding: 2.75rem 3.5rem;
    }
    @media (min-width: ${layouts.desktop}) {
        padding: 2.75rem 3.75rem;
    }
`;

export const Title = styled.h1`
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.87;
    letter-spacing: normal;
    margin-bottom: 1.5rem;
    @media (max-width: ${layouts.tablet}) {
        font-size: 2.25rem;
    }
    @media (min-width: ${layouts.tablet}) {
        font-size: 3rem;
    }
    @media (min-width: ${layouts.desktop}) {
        font-size: 3.75rem;
    }
`;

export const Subtitle = styled.h3`
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.18;
    letter-spacing: normal;
    margin: 0;
    margin-bottom: 2rem;
    @media (max-width: ${layouts.tablet}) {
        font-size: 1.65rem;
    }
    @media (min-width: ${layouts.tablet}) {
        font-size: 2.25rem;
    }
    @media (min-width: ${layouts.desktop}) {
        font-size: 2.75rem;
    }
`;

export const ContentContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    @media (max-width: ${layouts.mobile}) {
        grid-template-columns: 1fr;
    }
`;

export const CreatedBy = styled.div`
    font-size: 1.625rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.32;
    letter-spacing: normal;
    margin-bottom: 3.5rem;
    @media (max-width: ${layouts.tablet}) {
        font-size: 1rem;
    }
    @media (min-width: ${layouts.tablet}) {
        font-size: 1.3rem;
    }
    @media (min-width: ${layouts.desktop}) {
        font-size: 1.625rem;
    }
`;

export const Description = styled.div`
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.32;
    letter-spacing: normal;
    margin-bottom: 2.5rem;
    @media (max-width: ${layouts.tablet}) {
        font-size: 1.5rem;
    }
    @media (min-width: ${layouts.tablet}) {
        font-size: 2rem;
    }
    @media (min-width: ${layouts.desktop}) {
        font-size: 2.375rem;
    }
`;

export const BookContainer = styled.div`
    justify-self: center;
`;

export const BookImage = styled.img`
    width: 100%;
`;

export const GoToShopButton = styled.button`
    border-radius: 25px;
    border: solid 3px ${props => props.theme.tertiary};
    color: ${props => props.theme.tertiary};
    background-color: transparent;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: center;
    padding: 0 3rem;
    cursor: pointer;
    @media (max-width: ${layouts.tablet}) {
        font-size: 1rem;
    }
    @media (min-width: ${layouts.tablet}) {
        font-size: 1.3rem;
    }
    @media (min-width: ${layouts.desktop}) {
        font-size: 1.5rem;
    }
    @media (hover) {
        :hover {
            color: ${props => props.theme.primary};
            border-color: ${props => props.theme.primary};
        }
    }
`;
