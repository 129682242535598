import React from "react";

import PropTypes from "prop-types";
import moment from "moment";
import "moment/locale/it";

import {
    Container,
    PostContainer,
    PostWrapper,
    SuperLink,
    Thumbnail,
    PostDescription,
    PostAuthor,
    PostTitle,
    PostSubtitle
} from "./styled";

const Readable = str => {
    str = str.replace(/-/g, " ");
    return str.charAt(0).toUpperCase() + str.slice(1);
};

const BlogFeed = ({ articles, filterCat }) => {
    moment.locale("it");
    return (
        <Container>
            {articles &&
                articles.map(article => {
                    if (!filterCat.length || filterCat.some(item => article.categories.includes(item)))
                        return (
                            <PostContainer key={article.link}>
                                <SuperLink href={article.link} target="_blank">
                                    <PostWrapper>
                                        <Thumbnail src={article.thumbnail} alt={""} />
                                        <PostDescription>
                                            <PostAuthor>{article.author}</PostAuthor>
                                            <PostTitle>{article.title}</PostTitle>
                                            <PostSubtitle>
                                                {moment(article.pubDate).format("LL")}
                                                {article.categories.map(cat => (
                                                    <span key={cat}>
                                                        {" / "}
                                                        {Readable(cat)}
                                                    </span>
                                                ))}
                                            </PostSubtitle>
                                        </PostDescription>
                                    </PostWrapper>
                                </SuperLink>
                            </PostContainer>
                        );
                    else return null;
                })}
        </Container>
    );
};

BlogFeed.FeedHeader = {
    articles: PropTypes.object,
    filterCat: PropTypes.array
};

export default BlogFeed;
