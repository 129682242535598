import styled from "styled-components";

import RiseLoader from "react-spinners/RiseLoader";

import { layouts } from "../../../theme";

export const Container = styled.div`
    background-color: ${props => props.theme.white};
    color: ${props => props.theme.black};
    padding: 3.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    @media (max-width: ${layouts.mobile}) {
        padding: 2rem;
    }
`;

export const Title = styled.h2`
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.07;
    letter-spacing: normal;
    text-align: center;
    margin: 3rem auto;
    max-width: 876px;
    @media (max-width: ${layouts.tablet}) {
        font-size: 2.25rem;
    }
    @media (min-width: ${layouts.tablet}) {
        font-size: 3rem;
    }
    @media (min-width: ${layouts.desktop}) {
        font-size: 3.75rem;
    }
`;

export const Subtitle = styled.h3`
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.75rem;
    letter-spacing: normal;
    color: ${props => props.theme.black};
    @media (max-width: ${layouts.tablet}) {
        font-size: 1.5rem;
    }
    @media (min-width: ${layouts.tablet}) {
        font-size: 2rem;
    }
    @media (min-width: ${layouts.desktop}) {
        font-size: 2.375rem;
    }
`;

export const Button = styled.button`
    border-radius: 25px;
    border: solid 3px ${props => props.theme.tertiary};
    color: ${props => props.theme.tertiary};
    width: fit-content;
    font-size: 1.5rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: center;
    background-color: transparent;
    padding: 0 3rem;
    margin: 2rem;
    cursor: pointer;
    @media (max-width: ${layouts.tablet}) {
        font-size: 1rem;
    }
    @media (min-width: ${layouts.tablet}) {
        font-size: 1.2rem;
    }
    @media (min-width: ${layouts.desktop}) {
        font-size: 1.5rem;
    }

    @media (hover) {
        :hover {
            border-color: ${props => props.theme.primary};
            color: ${props => props.theme.primary};
        }
    }
`;

export const LoaderContainer = styled.div`
    padding: 3.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    @media (max-width: ${layouts.mobile}) {
        padding: 2rem;
    }
`;

export const Loader = styled(RiseLoader).attrs({
    height: "6rem",
    width: "1rem",
    color: "#18ffff"
})``;
