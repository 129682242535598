import React from "react";

import {
    BookContainer,
    Container,
    ContentContainer,
    CreatedBy,
    Description,
    GoToShopButton,
    Subtitle,
    Title,
    BookImage
} from "./styled";
import legalDesignBook from "../../image/libro-legal-design-demuro-imperiale.png";
import { FormattedMessage } from "react-intl";

const BookSection = () => {
    return (
        <Container>
            <Title>
                <FormattedMessage id="book-section.title" />
            </Title>
            <Subtitle>
                <FormattedMessage id="book-section.subtitle" />
            </Subtitle>
            <ContentContainer>
                <div>
                    <CreatedBy>
                        <FormattedMessage id="book-section.created-by" />
                    </CreatedBy>
                    <Description>
                        <FormattedMessage id="book-section.description" />
                    </Description>
                    <a href="https://shop.giuffre.it/024209860-legal-design.html" target="_blank" rel="noreferrer">
                        <GoToShopButton>
                            <FormattedMessage id="book-section.go-to-shop" />
                        </GoToShopButton>
                    </a>
                </div>
                <BookContainer>
                    <BookImage src={legalDesignBook} alt="libro legal design demuro imperiale" />
                </BookContainer>
            </ContentContainer>
        </Container>
    );
};

export default BookSection;
