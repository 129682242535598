import React from "react";

import { FormattedMessage } from "react-intl";
import { Link } from "@reach/router";

import {
    Container,
    HeroContainer,
    MonsterImage,
    MonsterImageContainer,
    Subtitle,
    Title,
    TitleContainer,
    GoHomeButton
} from "./styled";

import monsterDesignRights from "../../../image/mostro_design_rights.png";

const ErrorSection = () => (
    <Container>
        <HeroContainer>
            <TitleContainer>
                <Title>
                    <FormattedMessage
                        id="error-section.title"
                        values={{
                            br: <br />
                        }}
                    />
                </Title>
                <Subtitle>
                    <FormattedMessage
                        id="error-section.subtitle"
                        values={{
                            br: <br />
                        }}
                    />
                </Subtitle>
                <Link to={"/"}>
                    <GoHomeButton>
                        <FormattedMessage id="error-section.button" />
                    </GoHomeButton>
                </Link>
            </TitleContainer>
            <MonsterImageContainer>
                <MonsterImage src={monsterDesignRights} alt="mostro design rights" />
            </MonsterImageContainer>
        </HeroContainer>
    </Container>
);

export default ErrorSection;
