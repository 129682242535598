const en = {
    "blog-loading.title": "Loading ...",
    "blog-loading.subtitle": "We could not find any blog posts, please come back later",
    "blog-loading.button": "Back Home",

    "blog-hero.title": "Blog",
    "blog-hero.subtitle": "Present and future projects, events and news about legal design",

    "error-section.title": "Looks like you’re lost",
    "error-section.subtitle": "Maybe you've followed a broken link or entered a URL that doesn't exist on this site.",
    "error-section.button": "Back Home",

    "hero-section.title": "Legal design to {br} humanize the law",
    "hero-section.subtitle": "Simplify, improve access, generate awareness",

    "awarness-section.title": "Improving trust in the legal field thanks to clear and transparent content",
    "awarness-section.list.first": "easy to understand",
    "awarness-section.list.second": "inclusive",
    "awarness-section.list.third": "ideated taking into account users' needs",

    "revolutionaries-section.title": "Our impact on the world of law",
    "revolutionaries-section.page1.first.subtitle": "Leading company in the telco field",
    "revolutionaries-section.page1.first.description":
        "Summary of the Rules of Use for prepaid mobile cards: personas analysis, reduction of the content to the essential, finetuning of language and graphics. Better clarity of presentation and understandability of the text.",
    "revolutionaries-section.page1.second.subtitle": "Leading company in the energy field",
    "revolutionaries-section.page1.second.description":
        "Realization of a contract summary for the fiber services: reduction of the content to the essential, finetuning of lanaguage and graphics. Better and faster reading comprehension; increased possibility of information recall.",
    "revolutionaries-section.page2.first.subtitle": "Leading company in the IT field",
    "revolutionaries-section.page2.first.description":
        "Rewriting of the privacy policy for a website. Personas analysis, new design of text and information architecture. Better reading comprehension and information recall for the final users.",
    "revolutionaries-section.page2.second.subtitle": "Leading company in the cosmetics field",
    "revolutionaries-section.page2.second.description":
        "Rewriting of privacy policy and waiver for image rights. Reduction of the content to the essential, finetuning of language and graphic. Better understanding of the content for the signer.",
    "revolutionaries-section.page3.first.subtitle": "Leading company in the mass distribution",
    "revolutionaries-section.page3.first.description":
        "Theoretical and practical training for the legal team. Transmission of the inspiring values of legal design. Presentation of legal design thinking methodology and tools for the design of legal content.",
    "revolutionaries-section.page3.second.subtitle": " ",
    "revolutionaries-section.page3.second.description": "What kind of impact do you want to generate?",
    "revolutionaries-section.page3.second.start-now": "Start now",

    "legal-design-thinking-section.title": "Legal Design Thinking",
    "legal-design-thinking-section.subtitle": "An approach which is multidisciplinary by design",
    "legal-design-thinking-section.list.first": "+ transparency",
    "legal-design-thinking-section.list.second": "+ trust in the brand",
    "legal-design-thinking-section.list.third": "+ comprehension",
    "legal-design-thinking-section.list.fourth": "- claims",
    "legal-design-thinking-section.impact-on-the-world": "Impact on the world",
    "legal-design-thinking-section.how-we-can-help": "How can we help you?",
    "legal-design-thinking-section.how-we-can-help.consulting.title": "Consulting",
    "legal-design-thinking-section.how-we-can-help.consulting.description": "Writing and rewriting of legal content",
    "legal-design-thinking-section.how-we-can-help.legal-design-workshop.title": "Legal design workshops",
    "legal-design-thinking-section.how-we-can-help.legal-design-workshop.description":
        "Learning sessions for legals and employees",
    "legal-design-thinking-section.how-we-can-help.legal-tech-products.title": "Legal tech products",
    "legal-design-thinking-section.how-we-can-help.legal-tech-products.description":
        "Ideation and realization of innovative solutions in the legal field",
    "legal-design-thinking-section.how-we-can-help.tailor-made-services.title": "Tailor-made services",
    "legal-design-thinking-section.how-we-can-help.tailor-made-services.description": "Co-creation of custom projects",
    "legal-design-thinking-section.in-which-scope": "In which scope?",
    "legal-design-thinking-section.in-which-scope.contracts": "Contracts",
    "legal-design-thinking-section.in-which-scope.judicial-acts": "Judicial acts",
    "legal-design-thinking-section.in-which-scope.customer-service": "Customer service",
    "legal-design-thinking-section.in-which-scope.internal-policies": "Internal policies",
    "legal-design-thinking-section.in-which-scope.privacy": "Privacy",
    "legal-design-thinking-section.in-which-scope.and-more": "And more",

    "talks-section.title": "Disrupt the world with us!",
    "talks-section.button": "Talk with us",

    "design-righters.bdm.name": "Barbara",
    "design-righters.bdm.surname": "de Muro",
    "design-righters.bdm.bio":
        "Crazily in love with corporate law (well, with law in general), I always find myself at the intersection between law, creativity and ethics. I believe that this combination brings legal architectures to innovate themselves, projecting them to the future, towards unseen conquests that help society evolve.",
    "design-righters.cdr.name": "Cristina",
    "design-righters.cdr.surname": "Del Re",
    "design-righters.cdr.bio":
        "I help organizations finding a creative vision to innovate, communicate, and create value through concrete solutions, which are based on understanding the nature of human beings. Each year I improvise surreal travels around the world to photograph and meet different cultures.",
    "design-righters.fm.name": "Francesco",
    "design-righters.fm.surname": "Mondora",
    "design-righters.fm.bio":
        "I am a purposeful leader and - some would say - serial entrepreneur. My work is an excuse to bring good to the collectivity. I believe that every action should include our footprint to evolve, our sign that our work is inclusive and respectful of each one's needs. With this mindset I always built my organizations, helping them to become better for the world. The rest is on Linkedin",
    "design-righters.lz.name": "Lorenzo",
    "design-righters.lz.surname": "Zorzi",
    "design-righters.lz.bio":
        "I do believe that the mix of constant practice and visionary approach is the recipe for innovation. The result is, in other words, Glenn Gould. The long experience both in business consulting and digital communication taught me that we can bring value only when we work together with our clients, and not on their behalf.",
    "design-righters.mi.name": "Marco",
    "design-righters.mi.surname": "Imperiale",
    "design-righters.mi.bio":
        "Despite having seen more than 1000 concerts around the world, I found time to graduate in Italy (Bologna) and United States (Harvard). Each summer I walk to Santiago de Compostela, and whenever I find time I explore Italian lakes with my motorcycle, named Blackie.",

    "together-rights-sections.title": "Together for the rights",
    "together-rights-sections.lca.name": "LCA STUDIO LEGALE",
    "together-rights-sections.lca.description":
        "<b>LCA</b> is a law firm specialized in legal and fiscal advice, with offices in Milan, Genova, Treviso - also inside the tech incubator H-Farm - and Dubai, where it operates in partnership with IAA law firm",
    "together-rights-sections.mondora.name": "MONDORA SB SRL",
    "together-rights-sections.mondora.description":
        "<b>Mondora SB</b> srl is a company which offers IT Service consulting, software development for innovative applications, UX design and research, design thinking, tutoring and workshops in the IT field.{br}{br}It is a <b>benefit company and B CORP certified</b> since 2015. It is also part of the <b>Teamsystem</b> group.",

    "book-section.title": "Legal Design",
    "book-section.subtitle": "The book",
    "book-section.created-by": "Written by Barbara de Muro and Marco Imperiale",
    "book-section.description":
        "It is the first book which includes all the fundamental aspects of the discipiline. The reasons behind the success of legal design, the legal design thinkng process, techniques of legal writing and visual language, strategies for rewriting of legal texts, rules to organize content and composition of the page.",
    "book-section.go-to-shop": "Go to shop",

    "footer.talk-with-us": "Talk with us",
    "footer.copyright": "© 2021 mondora srl sb. All Rights Reserved. Viale Tunisia 4, 20124 Milano P.IVA 03680680968"
};

export default en;
