import React, { useState } from "react";

import { Router, Redirect } from "@reach/router";
import { ThemeProvider } from "styled-components";
import { ParallaxProvider } from "react-scroll-parallax";
import { IntlProvider } from "react-intl";

import { GlobalStyle, theme } from "./theme";

import Error from "./views/error";
import Home from "./views/home";
import Blog from "./views/blog";

import it from "./i18n/it";
import en from "./i18n/en";

const App = () => {
    const [locale, setLocale] = useState("it");

    const handleChangeLang = e => {
        setLocale(e);
    };

    const messages = locale => {
        switch (locale) {
            case "en":
                return en;
            default:
            case "it":
                return it;
        }
    };

    return (
        <IntlProvider key={locale} locale={locale} messages={messages(locale)} timeZoneName="it-IT">
            <ParallaxProvider>
                <ThemeProvider theme={theme}>
                    <Router>
                        <Error path="/404" changeLang={handleChangeLang} locale={locale} />
                        <Home path="/" changeLang={handleChangeLang} locale={locale} />
                        <Blog path="/blog" changeLang={handleChangeLang} locale={locale} />
                        <Redirect noThrow from="*" to="/404" />
                    </Router>
                    <GlobalStyle />
                </ThemeProvider>
            </ParallaxProvider>
        </IntlProvider>
    );
};

export default App;
