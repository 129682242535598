import React from "react";
import PropTypes from "prop-types";

import { faBrain, faHandshake, faSearch, faUniversity } from "@fortawesome/pro-regular-svg-icons";
import { Parallax } from "react-scroll-parallax";

import { layouts } from "../../theme";
import {
    Container,
    HelpContainer,
    HelpContent,
    HelpSubtitle,
    HelpUl,
    ImagesContainer,
    ImpactOnTheWorld,
    Infographic,
    LegalDesignThinkingPrinciplesContainer,
    ListDescription,
    ListIcon,
    ListItem,
    PrinciplesContainer,
    Separator,
    SeparatorContainer,
    Subtitle,
    Title,
    UnorderedList,
    ImpactLogo
} from "./styled";
import infographicLegalDesign from "../../image/infografica-legaldesign.svg";
import infographicLegalDesignEn from "../../image/infographic-legaldesign.svg";
import logoGlobalGoalsSdg from "../../image/logo-global-goals-sdg.svg";
import logoImpactSdg8 from "../../image/logo-impact-sdg8.svg";
import logoImpactSdg10 from "../../image/logo-impact-sdg10.svg";
import logoImpactSdg16 from "../../image/logo-impact-sdg16.svg";
import logoImpactSdg17 from "../../image/logo-impact-sdg17.svg";
import { FormattedMessage } from "react-intl";

const LegalDesignThinkingSection = ({ locale }) => {
    return (
        <Container>
            <Title>
                <FormattedMessage id="legal-design-thinking-section.title" />
            </Title>
            <Subtitle>
                <FormattedMessage id="legal-design-thinking-section.subtitle" />
            </Subtitle>
            <LegalDesignThinkingPrinciplesContainer>
                <Parallax
                    disabled={window.innerWidth < parseInt(layouts.mobile, 10)}
                    y={["0", "0"]}
                    x={["-100px", "0px"]}
                >
                    <Infographic
                        src={locale === "it" ? infographicLegalDesign : infographicLegalDesignEn}
                        alt="infografica legal design"
                    />
                </Parallax>
                <PrinciplesContainer>
                    <UnorderedList>
                        <ListItem>
                            <ListIcon icon={faSearch} />
                            <ListDescription>
                                <FormattedMessage id="legal-design-thinking-section.list.first" />
                            </ListDescription>
                        </ListItem>
                        <ListItem>
                            <ListIcon icon={faHandshake} />
                            <ListDescription>
                                <FormattedMessage id="legal-design-thinking-section.list.second" />
                            </ListDescription>
                        </ListItem>
                        <ListItem>
                            <ListIcon icon={faBrain} />
                            <ListDescription>
                                <FormattedMessage id="legal-design-thinking-section.list.third" />
                            </ListDescription>
                        </ListItem>
                        <ListItem>
                            <ListIcon icon={faUniversity} />
                            <ListDescription>
                                <FormattedMessage id="legal-design-thinking-section.list.fourth" />
                            </ListDescription>
                        </ListItem>
                    </UnorderedList>
                    <div>
                        <ImpactOnTheWorld>
                            <FormattedMessage id="legal-design-thinking-section.impact-on-the-world" />
                        </ImpactOnTheWorld>
                        <ImagesContainer>
                            <ImpactLogo src={logoImpactSdg8} alt="logo impact sdg 8" />
                            <ImpactLogo src={logoImpactSdg10} alt="logo impact sdg 10" />
                            <ImpactLogo src={logoImpactSdg16} alt="logo impact sdg 16" />
                            <ImpactLogo src={logoImpactSdg17} alt="logo impact sdg 17" />
                            <img src={logoGlobalGoalsSdg} alt="logo global goals sdg" />
                        </ImagesContainer>
                    </div>
                </PrinciplesContainer>
            </LegalDesignThinkingPrinciplesContainer>
            <SeparatorContainer>
                <Separator />
            </SeparatorContainer>
            <HelpContainer>
                <div>
                    <Title>
                        <FormattedMessage id="legal-design-thinking-section.how-we-can-help" />
                    </Title>
                    <HelpContent>
                        <HelpSubtitle>
                            <FormattedMessage id="legal-design-thinking-section.how-we-can-help.consulting.title" />
                        </HelpSubtitle>
                        <div>
                            <FormattedMessage id="legal-design-thinking-section.how-we-can-help.consulting.description" />
                        </div>
                        <HelpSubtitle>
                            <FormattedMessage id="legal-design-thinking-section.how-we-can-help.legal-design-workshop.title" />
                        </HelpSubtitle>
                        <div>
                            <FormattedMessage id="legal-design-thinking-section.how-we-can-help.legal-design-workshop.description" />
                        </div>
                        <HelpSubtitle>
                            <FormattedMessage id="legal-design-thinking-section.how-we-can-help.legal-tech-products.title" />
                        </HelpSubtitle>
                        <div>
                            <FormattedMessage id="legal-design-thinking-section.how-we-can-help.legal-tech-products.description" />
                        </div>
                        <HelpSubtitle>
                            <FormattedMessage id="legal-design-thinking-section.how-we-can-help.tailor-made-services.title" />
                        </HelpSubtitle>
                        <div>
                            <FormattedMessage id="legal-design-thinking-section.how-we-can-help.tailor-made-services.description" />
                        </div>
                    </HelpContent>
                </div>
                <div>
                    <Title>
                        <FormattedMessage id="legal-design-thinking-section.in-which-scope" />
                    </Title>
                    <HelpContent>
                        <HelpUl>
                            <li>
                                <FormattedMessage id="legal-design-thinking-section.in-which-scope.contracts" />
                            </li>
                            <li>
                                <FormattedMessage id="legal-design-thinking-section.in-which-scope.judicial-acts" />
                            </li>
                            <li>
                                <FormattedMessage id="legal-design-thinking-section.in-which-scope.customer-service" />
                            </li>
                            <li>
                                <FormattedMessage id="legal-design-thinking-section.in-which-scope.internal-policies" />
                            </li>
                            <li>
                                <FormattedMessage id="legal-design-thinking-section.in-which-scope.privacy" />
                            </li>
                            <li>
                                <FormattedMessage id="legal-design-thinking-section.in-which-scope.and-more" />
                            </li>
                        </HelpUl>
                    </HelpContent>
                </div>
            </HelpContainer>
        </Container>
    );
};

LegalDesignThinkingSection.propTypes = {
    locale: PropTypes.string
};

export default LegalDesignThinkingSection;
