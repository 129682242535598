import React, { useState } from "react";
import PropTypes from "prop-types";

import AwarenessSection from "../../components/awareness-section";
import BookSection from "../../components/book-section";
import DesignRighters from "../../components/design-righters";
import Footer from "../../components/footer";
import HeroSection from "../../components/hero-section";
import LegalDesignThinkingSection from "../../components/legal-design-thinking-section";
import PrivacyModal from "../../components/privacy-modal";
import RevolutionariesSection from "../../components/revolutionaries-section";
import TalksSection from "../../components/talks-section";
import TogetherRightsSection from "../../components/together-rights-section";
import { Container } from "./styled";
import TopMenu from "../../components/top-menu";

const Home = ({ changeLang, locale }) => {
    const [isPrivacyModalOpen, setIsPrivacyModalOpen] = useState(false);

    return (
        <>
            <Container isModalOpen={isPrivacyModalOpen}>
                <TopMenu changeLang={changeLang} locale={locale} />
                <HeroSection changeLang={changeLang} locale={locale} />
                <AwarenessSection />
                <RevolutionariesSection />
                <LegalDesignThinkingSection locale={locale} />
                <TalksSection />
                <DesignRighters />
                <TogetherRightsSection />
                <TalksSection />
                <BookSection />
                <Footer setIsPrivacyModalOpen={setIsPrivacyModalOpen} />
            </Container>
            {isPrivacyModalOpen && <PrivacyModal setIsPrivacyModalOpen={setIsPrivacyModalOpen} />}
        </>
    );
};

Home.propTypes = {
    changeLang: PropTypes.func,
    locale: PropTypes.string
};

export default Home;
