import React, { useEffect, useState } from "react";

import { FormattedMessage } from "react-intl";
import { Link } from "@reach/router";

import { Container, Title, Button, Subtitle, Loader, LoaderContainer } from "./styled";

const BlogLoading = () => {
    const [timedOut, setTimedOut] = useState(false);
    useEffect(() => {
        const timer = setTimeout(() => {
            setTimedOut(true);
        }, 4000);
        return () => clearTimeout(timer);
    }, []);

    return (
        <Container>
            {!timedOut ? (
                <LoaderContainer>
                    <Loader />
                    <Title>
                        <FormattedMessage id="blog-loading.title" />
                    </Title>
                </LoaderContainer>
            ) : (
                <>
                    <Subtitle>
                        <FormattedMessage id="blog-loading.subtitle" />
                    </Subtitle>
                    <Link to={"/"}>
                        <Button>
                            <FormattedMessage id="blog-loading.button" />
                        </Button>
                    </Link>
                </>
            )}
        </Container>
    );
};

export default BlogLoading;
