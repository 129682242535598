import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { Container, FeedContainer } from "./styled";

import TopMenu from "../../components/top-menu";
import Footer from "../../components/footer";
import PrivacyModal from "../../components/privacy-modal";
import BlogHero from "../../components/blog-page/blog-hero";
import BlogLoading from "../../components/blog-page/blog-loading";
import FeedHeader from "../../components/blog-page/feed-header";
import BlogFeed from "../../components/blog-page/blog-feed";

const USERNAME = process.env.REACT_APP_MEDIUM_USER;

const Blog = ({ changeLang, locale }) => {
    const [isPrivacyModalOpen, setIsPrivacyModalOpen] = useState(false);
    const [rssFeed, setRssFeed] = useState();
    const [filterCat, setFilterCat] = useState([]);

    useEffect(() => {
        var feedDate = new Date();
        if (!rssFeed || Date.now() - feedDate > 6000000) {
            feedDate = new Date();
            const mediumUrl =
                "REACT_APP_RSS_PROXY" in process.env
                    ? `
                    ${process.env["REACT_APP_RSS_PARSER"]}${
                          process.env["REACT_APP_RSS_PROXY"]
                      }https://medium.com/feed/${USERNAME}&rand=${Math.random()}`
                    : `https://medium.com/feed/${USERNAME}`;
            const loadArticles = async () => {
                fetch(mediumUrl, { headers: {} })
                    .then(res => res.json())
                    .then(articles => articles && setRssFeed(articles))
                    .catch(error => console.log(error));
            };
            loadArticles();
        }
    });

    return (
        <>
            <Container isModalOpen={isPrivacyModalOpen}>
                <TopMenu changeLang={changeLang} locale={locale} />
                <BlogHero />
                {rssFeed ? (
                    <FeedContainer>
                        <FeedHeader feed={rssFeed} filterCat={filterCat} setFilterCat={setFilterCat} />
                        <BlogFeed filterCat={filterCat} articles={rssFeed.items} />
                    </FeedContainer>
                ) : (
                    <BlogLoading />
                )}
                <Footer setIsPrivacyModalOpen={setIsPrivacyModalOpen} />
            </Container>
            {isPrivacyModalOpen && <PrivacyModal setIsPrivacyModalOpen={setIsPrivacyModalOpen} />}
        </>
    );
};

Blog.propTypes = {
    changeLang: PropTypes.func,
    locale: PropTypes.string
};

export default Blog;
