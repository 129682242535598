import styled from "styled-components";
import { layouts } from "../../theme";

export const Container = styled.div`
    background-color: ${props => props.theme.black};
    color: ${props => props.theme.white};
    @media (max-width: ${layouts.mobile}) {
        padding: 0 2rem 2.75rem 2rem;
    }
    @media (min-width: ${layouts.mobile}) {
        padding: 0 3.5rem 2.75rem 3.5rem;
    }
    @media (min-width: ${layouts.desktop}) {
        padding: 0 7.5rem 2.75rem 7.5rem;
    }
`;
export const HeroContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: auto;
    grid-template-areas: "title title title title title title image image image image image image";
    @media (max-width: ${layouts.mobile}) {
        grid-template-columns: repeat(6, 1fr);
        grid-template-areas:
            "title title title title title title"
            "image image image image image image";
    }
`;

export const TitleContainer = styled.div`
    grid-area: title;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const Title = styled.h1`
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.19;
    letter-spacing: normal;
    color: ${props => props.theme.white};
    @media (max-width: ${layouts.tablet}) {
        font-size: 3rem;
    }
    @media (min-width: ${layouts.tablet}) {
        font-size: 4rem;
    }
    @media (min-width: ${layouts.desktop}) {
        font-size: 5rem;
    }
`;

export const Subtitle = styled.h3`
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.75rem;
    letter-spacing: normal;
    color: ${props => props.theme.white};
    @media (max-width: ${layouts.tablet}) {
        font-size: 1.5rem;
    }
    @media (min-width: ${layouts.tablet}) {
        font-size: 2rem;
    }
    @media (min-width: ${layouts.desktop}) {
        font-size: 2.375rem;
    }
`;

export const MonsterImageContainer = styled.div`
    grid-area: image;
    text-align: center;

    @media (min-width: ${layouts.desktop}) {
        max-width: 40vw;
    }
`;

export const MonsterImage = styled.img`
    position: relative;
    top: 80px;
    width: 100%;
`;
