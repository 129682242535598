import React from "react";

import { FormattedMessage } from "react-intl";
import { Parallax } from "react-scroll-parallax";

import { layouts } from "../../../theme";
import {
    Container,
    HeroContainer,
    MonsterImage,
    MonsterImageContainer,
    Subtitle,
    Title,
    TitleContainer
} from "./styled";

import monsterDesignRights from "../../../image/mostro_design_rights.png";

const BlogHero = () => {
    return (
        <Container>
            <HeroContainer>
                <TitleContainer>
                    <Title>
                        <FormattedMessage
                            id="blog-hero.title"
                            values={{
                                br: <br />
                            }}
                        />
                    </Title>
                    <Subtitle>
                        <FormattedMessage
                            id="blog-hero.subtitle"
                            values={{
                                br: <br />
                            }}
                        />
                    </Subtitle>
                </TitleContainer>
                <MonsterImageContainer>
                    <Parallax
                        disabled={window.innerWidth < parseInt(layouts.mobile, 10)}
                        y={["-180px", "20px"]}
                        x={["0", "0"]}
                    >
                        <MonsterImage src={monsterDesignRights} alt="mostro design rights" style={{ width: "100%" }} />
                    </Parallax>
                </MonsterImageContainer>
            </HeroContainer>
        </Container>
    );
};

export default BlogHero;
