import React from "react";
import { FormattedMessage } from "react-intl";

import logoLca from "../../image/logo-lca.svg";
import logoMondora from "../../image/logo-mondora.svg";
import { Container, ContentWrapper, Description, Header, Title } from "./styled";

const TogetherRightsSection = () => {
    return (
        <Container>
            <Title>
                <FormattedMessage id="together-rights-sections.title" />
            </Title>
            <ContentWrapper>
                <div>
                    <Header href="https://www.lcalex.it/" target="_blank" rel="noreferrer">
                        <img src={logoLca} alt="logo lca" />
                        <FormattedMessage id="together-rights-sections.lca.name" />
                    </Header>
                    <Description>
                        <FormattedMessage
                            id="together-rights-sections.lca.description"
                            values={{
                                b: chunks => <b>{chunks}</b>,
                                br: <br />
                            }}
                        />
                    </Description>
                </div>
                <div>
                    <Header href="https://www.mondora.com/" target="_blank" rel="noreferrer">
                        <img src={logoMondora} alt="logo mondora" />
                        <FormattedMessage id="together-rights-sections.mondora.name" />
                    </Header>
                    <Description>
                        <FormattedMessage
                            id="together-rights-sections.mondora.description"
                            values={{
                                b: chunks => <b>{chunks}</b>,
                                br: <br />
                            }}
                        />
                    </Description>
                </div>
            </ContentWrapper>
        </Container>
    );
};

export default TogetherRightsSection;
