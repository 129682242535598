import { faDolly, faGlobe, faLightbulb, faLips, faMobile, faUnicorn } from "@fortawesome/pro-light-svg-icons";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import { layouts } from "../../theme";
import {
    Container,
    Revolutionist,
    Title,
    RevolutionistContainer,
    RevolutionistDescription,
    Icon,
    Button,
    UnicornSection
} from "./styled";

const RevolutionariesSection = () => {
    const intl = useIntl();

    const entries = [
        {
            icon: faMobile,
            subtitle: intl.formatMessage({
                id: "revolutionaries-section.page1.first.subtitle"
            }),
            description: intl.formatMessage({
                id: "revolutionaries-section.page1.first.description"
            })
        },
        {
            icon: faLightbulb,
            subtitle: intl.formatMessage({
                id: "revolutionaries-section.page1.second.subtitle"
            }),
            description: intl.formatMessage({
                id: "revolutionaries-section.page1.second.description"
            })
        },
        {
            icon: faGlobe,
            subtitle: intl.formatMessage({
                id: "revolutionaries-section.page2.first.subtitle"
            }),
            description: intl.formatMessage({
                id: "revolutionaries-section.page2.first.description"
            })
        },
        {
            icon: faLips,
            subtitle: intl.formatMessage({
                id: "revolutionaries-section.page2.second.subtitle"
            }),
            description: intl.formatMessage({
                id: "revolutionaries-section.page2.second.description"
            })
        },
        {
            icon: faDolly,
            subtitle: intl.formatMessage({
                id: "revolutionaries-section.page3.first.subtitle"
            }),
            description: intl.formatMessage({
                id: "revolutionaries-section.page3.first.description"
            })
        },
        {
            icon: faUnicorn,
            subtitle: "",
            description: (
                <UnicornSection>
                    {intl.formatMessage({
                        id: "revolutionaries-section.page3.second.description"
                    })}
                    <a href="https://www.calendly.com/designrights" target="_blank" rel="noreferrer">
                        <Button>
                            {intl.formatMessage({
                                id: "revolutionaries-section.page3.second.start-now"
                            })}
                        </Button>
                    </a>
                </UnicornSection>
            )
        }
    ];
    const pages = entries.reduce(function (rows, key, index) {
        return (
            (index % (window.innerWidth < parseInt(layouts.mobile, 10) ? 1 : 2) === 0
                ? rows.push([key])
                : rows[rows.length - 1].push(key)) && rows
        );
    }, []);

    return (
        <Container>
            <Title>
                <FormattedMessage id="revolutionaries-section.title" />
            </Title>
            <Carousel
                axis="horizontal"
                autoPlay={true}
                dynamicHeight={true}
                infiniteLoop={true}
                showThumbs={false}
                showArrows={false}
                showStatus={false}
                stopOnHover={true}
            >
                {pages.map((page, key) => (
                    <RevolutionistContainer key={key}>
                        {page.map((p, key) => (
                            <React.Fragment key={key}>
                                <Revolutionist>
                                    <Icon icon={p.icon} />
                                    <div>{p.subtitle}</div>
                                </Revolutionist>
                                <RevolutionistDescription>{p.description}</RevolutionistDescription>
                            </React.Fragment>
                        ))}
                    </RevolutionistContainer>
                ))}
            </Carousel>
        </Container>
    );
};

export default RevolutionariesSection;
