import { createGlobalStyle } from "styled-components";

export const theme = {
  white: "white",
  black: "#000000",
  primary: "#18ffff",
  secondary: "#a27f89",
  tertiary: "#26c6da",
  lightGrey: "#fefefe",
  grey: "#eceff1"
};

export const layouts = {
  mobile: "768px",
  tablet: "992px",
  desktop: "1200px"
}

export const GlobalStyle = createGlobalStyle`
  body {
    margin: 0px;
    font-size: 16px;
    font-family: "Open Sans", sans-serif;
  }
`;
