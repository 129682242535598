import styled from "styled-components";
import { layouts } from "../../theme";

export const Container = styled.div`
    background-color: ${props => props.theme.black};
    color: ${props => props.theme.white};
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.35;
    letter-spacing: normal;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2rem solid ${props => props.theme.secondary};
    @media (max-width: ${layouts.tablet}) {
        font-size: 1.5rem;
        padding: 2rem 2rem;
        flex-wrap: wrap;
        gap: 2rem;
    }
    @media (min-width: ${layouts.mobile}) {
        padding: 2rem 3.5rem;
    }
    @media (min-width: ${layouts.tablet}) {
        font-size: 2rem;
    }
    @media (min-width: ${layouts.desktop}) {
        font-size: 2.375rem;
        padding: 2rem 7.75rem;
    }
`;

export const TalksLink = styled.a`
    @media (max-width: ${layouts.mobile}) {
        margin: auto;
    }
`;

export const TalksButton = styled.button`
    border-radius: 25px;
    border: solid 3px ${props => props.theme.white};
    font-size: 1.5rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: center;
    background-color: transparent;
    color: ${props => props.theme.white};
    padding: 0 3rem;
    cursor: pointer;
    @media (max-width: ${layouts.mobile}) {
        font-size: 1rem;
    }
    @media (min-width: ${layouts.tablet}) {
        font-size: 1.2rem;
    }
    @media (min-width: ${layouts.desktop}) {
        font-size: 1.5rem;
    }

    :hover {
        border-color: ${props => props.theme.primary};
        color: ${props => props.theme.primary};
    }
`;
