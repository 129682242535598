import React from "react";

import PropTypes from "prop-types";

import { Container, Button } from "./styled";

const Readable = str => {
    str = str.replace(/-/g, " ");
    return str.charAt(0).toUpperCase() + str.slice(1);
};

const FeedHeader = ({ feed, filterCat, setFilterCat }) => {
    const addCategory = category => {
        filterCat.length
            ? filterCat.indexOf(category) !== -1
                ? setFilterCat(filterCat.filter(item => item !== category))
                : setFilterCat([...filterCat, category])
            : setFilterCat([category]);
    };

    let categories = [];
    feed.items.map(item => (categories = categories.concat(item.categories)));
    categories = categories.filter((item, pos) => categories.indexOf(item) === pos);

    return (
        <Container>
            {categories.map(category => (
                <Button
                    active={filterCat.indexOf(category) !== -1}
                    key={category}
                    onClick={() => addCategory(category)}
                >
                    {Readable(category)}
                </Button>
            ))}
        </Container>
    );
};

FeedHeader.FeedHeader = {
    feed: PropTypes.object,
    filterCat: PropTypes.array,
    setFilterCat: PropTypes.func
};

export default FeedHeader;
